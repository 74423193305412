<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const route = useRoute();
const request = useRequestURL();

useHead(computed(() => {
  const canonicalUrl = `${request.origin}${route.fullPath}`;

  return {
    htmlAttrs: {lang: 'nl'},
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} - Uitvaart-Platform` : 'Uitvaart-Platform';
    },
    charset: 'utf-8',
    link: [{
      rel: 'canonical',
      href: canonicalUrl,
    }],
    script: [{
      id: 'mcjs',
      async: true,
      src: 'https://chimpstatic.com/mcjs-connected/js/users/2dac18adba33d8dbabe072517/c8c2ceabea147cc316d5626f6.js',
    }],
  };
}));
</script>
